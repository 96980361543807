import React from "react"
import { navigate } from "gatsby"
import { Button } from "reactstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AlreadyHaveAccountBanner from "./alreadyHaveAccountBanner"

export default function SignUp() {
  return (
    <Layout>
      <SEO title="Sign Up" />

      <div className="text-center pt-4">
        <h1 className="bold text-xxl text--primary mb-5">Create a New Wordcraft Account</h1>

        <div className="flex-center flex-column gap-20px">
          <Button
            className="width-250px"
            color="primary"
            onClick={() => {
              navigate("/signup/student")
            }}
            size="lg"
          >
            I'm a student
          </Button>

          <Button
            color="primary"
            className="width-250px"
            onClick={() => {
              navigate("/signup/teacher")
            }}
            size="lg"
          >
            I'm a teacher
          </Button>

          <Button
            outline
            color="primary"
            className="width-250px"
            onClick={() => {
              navigate("/signup/individual")
            }}
            size="lg"
          >
            I just want to learn
          </Button>
        </div>

        <AlreadyHaveAccountBanner />
      </div>
    </Layout>
  )
}
